export const mrStates = {
  merged: 'merged',
  closed: 'closed',
};

export const humanMRStates = {
  merged: 'Merged',
  closed: 'Closed',
  open: 'Open',
};
